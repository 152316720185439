body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Add to your global CSS */
.katex .accent {
  background-color: transparent !important;
  border-color: transparent !important;
}

@font-face {
  font-family: "Gilroy-Black";
  src: local("Gilroy-Black"), url(./fonts/Gilroy-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-BlackItalic";
  src: local("Gilroy-BlackItalic"),
    url(./fonts/Gilroy-BlackItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"), url(./fonts/Gilroy-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-BoldItalic";
  src: local("Gilroy-BoldItalic"),
    url(./fonts/Gilroy-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-ExtraBold";
  src: local("Gilroy-ExtraBold"),
    url(./fonts/Gilroy-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Heavy";
  src: local("Gilroy-Heavy"), url(./fonts/Gilroy-Heavy.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-HeavyItalic";
  src: local("Gilroy-HeavyItalic"),
    url(./fonts/Gilroy-HeavyItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"), url(./fonts/Gilroy-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-LightItalic";
  src: local("Gilroy-LightItalic"),
    url(./fonts/Gilroy-LightItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"), url(./fonts/Gilroy-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-MediumItalic";
  src: local("Gilroy-MediumItalic"),
    url(./fonts/Gilroy-MediumItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Regular";
  src: local("Gilroy-Regular"),
    url(./fonts/Gilroy-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-RegularItalic";
  src: local("Gilroy-RegularItalic"),
    url(./fonts/Gilroy-RegularItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-SemiBold";
  src: local("Gilroy-SemiBold"),
    url(./fonts/Gilroy-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-SemiBoldItalic";
  src: local("Gilroy-SemiBoldItalic"),
    url(./fonts/Gilroy-SemiBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Thin";
  src: local("Gilroy-Thin"), url(./fonts/Gilroy-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-ThinItalic";
  src: local("Gilroy-ThinItalic"),
    url(./fonts/Gilroy-ThinItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-UltraLight";
  src: local("Gilroy-UltraLight"),
    url(./fonts/Gilroy-UltraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-UltraLightItalic";
  src: local("Gilroy-UltraLightItalic"),
    url(./fonts/Gilroy-UltraLightItalic.ttf) format("truetype");
}

@font-face {
  font-family: "DM-Sans";
  src: local("DMSans-Regular"),
    url(./fonts/DMSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Inter-Tight";
  src: local("InterTight-VariableFont_wght"),
    url(./fonts/InterTight-VariableFont_wght.ttf) format("truetype");
}

.custom-input .ant-input-group-addon {
  background-color: #f5f7fa !important; /* Match the background of the input field */
  border: none !important; /* Remove the border */
  padding: 0 8px !important; /* Adjust spacing */
  border-radius: 8px 0 0 8px !important; /* Seamless border-radius */
  outline: none !important;
}

.custom-input .ant-input {
  background-color: #f5f7fa !important; /* Match input background */
  border: none !important; /* Remove border */
  padding-left: 8px !important; /* Adjust padding to align text */
  border-radius: 0 8px 8px 0 !important; /* Seamless border-radius */
  outline: none !important;
}

.custom-input:focus,
.custom-input:active {
  background-color: #f5f7fa !important; /* Prevent the background from changing */
  border-color: transparent !important; /* Ensure no border appears */
  outline: none !important;
  box-shadow: none !important; /* Prevent any shadow */
}

.ant-select-selector {
  background-color: #f5f7fa !important;
}

.header-search-input > .ant-input,
.header-search-input > .ant-input::placeholder,
.header-search-input .ant-input-group-addon .ant-input {
  font-family: "Gilroy-Medium" !important;
  font-size: 12px !important;
  color: #566268 !important;
  margin-left: 6px !important;
}

.additional-settings-collapse .ant-collapse-content {
  background-color: #f5f7fa !important;
  border: none !important;
}

.additional-settings-text > .ant-collapse-header .ant-collapse-header-text {
  font-family: "Gilroy-Medium" !important;
  color: #145365 !important;
  font-size: "14px" !important;
}

.quiz-type-select.ant-select .ant-select-selector {
  border: none !important;
  background-color: #f5f7fa !important;
}

/* Target the selection text */
.quiz-type-select.ant-select .ant-select-selection-item {
  background-color: #ffffff !important;
  color: #145365 !important;
  margin: 3px !important;
}

.difficulty-select.ant-select .ant-select-selector {
  border: none !important;
  background-color: #f5f7fa !important;
}

/* Target the selection text */
.difficulty-select.ant-select .ant-select-selection-item {
  color: #145365 !important;
}

.ant-modal-content {
  border-radius: 16px !important;
  padding: 28px !important;
}

.num-questions-input-number.ant-input-number {
  border: none !important;
}

.num-questions-input-number .ant-input-number-input {
  color: #145365 !important;
}

.ant-spin-dot-item {
  background-color: #145365 !important;
}

/* .ant-input:focus,
.ant-input-focused,
.ant-input:hover {
  border: none;
  box-shadow: none;
} */

/* For Input Groups (e.g., addonBefore) */
/* .ant-input-group .ant-input:focus,
.ant-input-group .ant-input:hover {
  border: none;
  box-shadow: none;
} */

/* .ant-input-group-addon {
  border: none;
} */

.ant-select-selection-placeholder {
  padding-left: 35px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #e6f1ff;
}

::-webkit-scrollbar-thumb {
  background: #ff6c42;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ff8968;
  cursor: pointer;
}
